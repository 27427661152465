import { Text } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import { type Location } from "@src/appV2/lib/GoogleMaps";

import { useGetAddressByGeoCode } from "../../lib/GoogleMaps/api/useGetAddressByGeoCode";
import { OpenGeoLocationProvider } from "../components/OpenGeoLocation/useOpenGeoLocation";
import { GoogleMapCustomMarkerWrapper } from "./GoogleMapCustomMarkerWrapper";
import { MapMarkerActive } from "./MapMarkerActive";
import { MapWithAddressAction } from "./MapWithAddressAction";

interface MapWithAddressProps {
  workplaceId: string;
  workplaceName?: string;
  location: Location;
  readonly?: boolean;
}

export function MapWithAddress(props: MapWithAddressProps) {
  const { workplaceId, workplaceName, location, readonly } = props;

  const { data: address, isSuccess: isAddressSuccess } = useGetAddressByGeoCode(location);

  /**
   * Formats the address to be displayed in two lines
   */
  const formatAddress = () => {
    if (!isAddressSuccess) {
      return "";
    }

    const split = address.formatted?.split(", ") ?? [];

    return `${split.slice(0, 2).join(", ")}\n${split.slice(2).join(", ")}`;
  };

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative", overflow: "hidden" }}>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          center: location,
          disableDefaultUI: true,
          // Cooperative gesture handling works better if we put this inside a scroll view as
          // scroll events should not interfere with the map gestures
          gestureHandling: readonly ? "none" : "cooperative",
        }}
        onLoad={(map) => {
          // TODO: Investigate and abstract zoom values into an enum like `MapZoomLevel`
          map.setZoom(15);
        }}
      >
        <GoogleMapCustomMarkerWrapper position={location}>
          <MapMarkerActive />
        </GoogleMapCustomMarkerWrapper>
      </GoogleMap>

      {isAddressSuccess && (
        <OpenGeoLocationProvider
          location={location}
          name={workplaceName}
          formattedAddress={address.formatted}
        >
          <MapWithAddressAction workplaceId={workplaceId}>
            <Text variant="body2">{formatAddress()}</Text>
          </MapWithAddressAction>
        </OpenGeoLocationProvider>
      )}
    </Box>
  );
}
