import { ButtonBase } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { OpenGeoLocationBottomSheet } from "../components/OpenGeoLocation/OpenGeoLocationBottomSheet";
import { useOpenGeoLocationContext } from "../components/OpenGeoLocation/useOpenGeoLocation";
import { Pill } from "../components/Pill";

export type MapWithAddressActionBehavior = "copy" | "open";

interface MapWithAddressActionProps {
  workplaceId: string;
  children: React.ReactNode;
}

export function MapWithAddressAction(props: MapWithAddressActionProps) {
  const { workplaceId, children } = props;

  const { href, onClick } = useOpenGeoLocationContext();

  return (
    <ButtonBase
      sx={(theme) => ({
        position: "absolute",
        bottom: theme.spacing(5),
        width: `calc(100% - 2 * ${theme.spacing(5)})`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: String(theme.palette.background.tertiary),
        padding: 5,
        borderRadius: theme.borderRadius?.small,
        left: theme.spacing(5),
        right: theme.spacing(5),
        boxShadow: theme.shadows[3],
        whiteSpace: "pre",
        textAlign: "left",
        overflow: "hidden",
        textWrap: "balance",
      })}
      onClick={async (event) => {
        event.stopPropagation();
        event.preventDefault();

        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_OPEN_WORKPLACE_MAP, { workplaceId });
        if (onClick) {
          onClick();
        } else {
          window.open(href, "_blank");
        }
      }}
    >
      {children}

      <Pill label="Open in map" size="medium" />
      <OpenGeoLocationBottomSheet />
    </ButtonBase>
  );
}
