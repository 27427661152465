import { Text, type TextProps } from "@clipboard-health/ui-react";
import { type DateRange } from "@src/appV2/lib";

import { DotSeparatedList } from "../components/DotSeparatedList";
import { formatDayOfMonthWithDayName } from "../utils/formatDayOfMonthWithDayName";
import { formatHoursDurationCompact } from "../utils/formatHoursDurationCompact";
import { getFormattedTimeRangeWithTimezone } from "../utils/getFormattedTimeRangeWithTimezone";

export interface DateWithDurationProps extends TextProps {
  dateRange: DateRange;
  durationInHours: number;
  timezone?: string;
  deviceTimezone?: string;
  showTimeRange?: boolean;
}

export function DateWithDuration(props: DateWithDurationProps) {
  const {
    dateRange,
    durationInHours,
    timezone,
    deviceTimezone,
    showTimeRange = false,
    ...textProps
  } = props;

  const { label, timezoneAbbreviation } = getFormattedTimeRangeWithTimezone({
    dateRange,
    deviceTimezone,
    timezone,
  });

  return (
    <Text {...textProps}>
      <DotSeparatedList>
        {formatDayOfMonthWithDayName(dateRange.startDate, { timezone })}
        {showTimeRange && (timezoneAbbreviation ? `${label} (${timezoneAbbreviation})` : label)}
        {formatHoursDurationCompact(durationInHours)}
      </DotSeparatedList>
    </Text>
  );
}
